@import '../../assets/styles/variables.scss';
.home{
    display: flex;
    flex-flow: column;
    gap: .5rem;
    &-header{
        height: 3.5rem;
        display: flex;
        padding: .5rem;
        align-items: center;
        border-bottom: 1px solid $decorative-subdued;
    }
    .nav-tabs{
        padding: .5rem 0;
        display: flex;
        width: 100%;
        gap: .5rem;
        height: 3.5rem;
        max-width: 900px;
        .button{
            background: transparent;
            flex: 1;
            border-radius: 5px;
            gap: .5rem;
            max-width: 300px;
            justify-content: flex-start;
            position: relative;
            background: $bg-elevated-highlight;
            &:hover{
                background: $bg-upper-base;
            }
            &.active{
                background: $branch-color;
                color: $text-base;
            }
        }
    }
    .sections-layout{
        display: flex;
        flex-flow: column;
        gap: 1rem;
        padding: .5rem;
        &__body{
            display: flex;
            flex-flow: column;
            gap: 1rem;
            .section-container{
                .header{
                    font-size: 1.5rem;
                    display: flex;
                    align-items: center;
                }
                .body{
                    display: flex;
                    overflow-x: scroll;
                    margin: 0 -.5rem;
                    padding-bottom: 1rem;
                    .card{
                        width: 100%;
                        min-width: 200px;
                        max-width: 200px;
                        display: flex;
                        flex-flow: column;
                        justify-content: flex-start;
                        color: $text-base;
                        padding: .5rem;
                        border-radius: 0;
                        transition: 0s !important;
                        &:hover{
                            background: $bg-elevated-base;
                        }
                        &.add{
                            .thumbnail{
                                svg{
                                    width: 3rem;
                                    height: 3rem;
                                }
                            }
                        }
                        .thumbnail{
                            width: 100%;
                            aspect-ratio: 2 / 3;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: $bg-highlight;
                            margin-bottom: .5rem;
                            position: relative;
                            .button.variant-danger{
                                position: absolute;
                                bottom: .25rem;
                                left: .25rem;
                                right: .25rem;
                                background: $bg-base;
                                border-radius: 5px;
                                &:hover{
                                    background: #c0392b;
                                }
                            }
                            picture{
                                display: flex;
                            }
                            img{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .banner-layout{
        display: flex;
        flex-flow: column;
        gap: 1rem;
        padding: .5rem;
        &__body{
            display: flex;
            flex-flow: row wrap;
            gap: 1rem;
            .search-results{
                position: absolute;
                top: 100%;
                width: 100%;
                padding: .25rem;
                background: $bg-base;
                border: 1px solid $decorative-subdued;
                border-top: none;
                min-height: 200px;
                max-height: 200px;
                overflow-y: scroll;
                z-index: 1;
            }
        }
        &__container{
            aspect-ratio: 16 / 9;
            border: 1px solid $decorative-subdued;
            padding: .5rem;
            flex: 1;
            overflow: hidden;
            border-radius: 5px;
            position: relative;
            min-width: 600px;
            &.loading{
                opacity: .5;
            }
            .background{
                position: absolute;
                top: 0;
                left: 0;
                img { 
                    width: 100%;
                }
            }
            .action-buttons{
                position: absolute;
                top: .5rem;
                right: .5rem;
                z-index: 1;
                display: flex;
                align-items: center;
                gap: .5rem;
                background: $bg-base;
                border-radius: 4px;
                .IconButton{
                    .button-icon{
                        display: flex;
                    }
                }
                .button{
                    min-width: inherit;
                }
            }
            .form{
                .number-inputs{
                    display: flex;
                    gap: 1rem;
                }
            }
            &-body{
                position: relative;
                display: flex;
                height: 100%;
                gap: 1rem;
                .thumbnail-mv{
                    aspect-ratio: 2 / 3;
                    height: 100%;
                    width: auto;
                    padding: 0;
                    background: $bg-highlight;
                    img{
                        width: 100%;
                    }
                }
                .thumbnail-logo{
                    width: 100%;
                    background: transparent;
                    img{
                        width: 100%;
                    }
                }
                .data{
                    flex: 1;
                    margin-top: auto;
                }
            }
        }
    }
}