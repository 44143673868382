@import '../assets/styles/variables.scss';

.login-layout{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .login-body{
        display: flex;
        max-width: 500px;
        width: 100%;
        background: $bg-highlight;
    }
}

.layout{
    display: flex;
    .content{
        flex: 1;
        max-height: 100vh;
        overflow-x: hidden;
        overflow-y: scroll;
    }
}