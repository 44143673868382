$branch-color: #4169e1;
$branch-color-light: #527dff;
$branch-color-darker: #2e4ba1;

$bg-base: #121212;
$bg-base-tranparent: #12121233;
$bg-highlight: #1a1a1a;
$bg-press: #000;
$bg-elevated-base: #242424;
$bg-elevated-highlight: #2a2a2a;
$bg-elevated-press: #000;
$bg-upper-base: #363636;
$bg-upper-highlight: #3a3a3a;
$bg-upper-press: #000;
$text-base: #fff;
$text-subdued: #a7a7a7;
$text-highlight: #121212;
$text-base-highlight: #d9d9d9;
$text-negative: #f15e6c;
$text-positive: #1ed760;
$decorative-base: #fff;
$decorative-subdued: #292929;
$decorative-highlight: #393939;
$decorative-highlight-press: #dadada;