@import '../../assets/styles/variables.scss';
.upload{
    display: flex;
    > div{
        flex: 1;
        padding: 1rem .5rem;
    }
    .form-layout{
        height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
        .form{
            display: flex;
            max-width: 700px;
            margin: 0 auto;
            flex-flow: column;
            .section{
                padding-bottom: 1.5rem;
                border-bottom: 1px solid $decorative-subdued;
                &:nth-last-child(1){
                    border-color: transparent;
                }
                h2{
                    text-transform: uppercase;
                    font-family: $font-branch;
                    font-size: 1rem;
                    font-weight: 600;
                    margin-bottom: 1rem;
                }
                .chips{
                    background: $bg-upper-base;
                    color: $text-subdued;
                    margin-right: .5rem;
                }
            }
            .buttons-group{
                position: sticky;
                bottom: 0;
                &::before{
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    top: 100%;
                    background: $bg-base;
                    height: 1rem;
                }
            }
        }
    }
    .preview-container{
        flex: 0.5;
        h2{
            font-size: 1rem;
            font-family: $font-branch;
            text-transform: uppercase;
            font-weight: 600;
            margin-bottom: .5rem;
        }
        .data-img{
            aspect-ratio: 16 / 9;
            width: 100%;
            position: relative;
            background-size: cover !important;
            background-position: center;
            background: $bg-highlight;
            border-radius: 2px;
            .img{
                position: absolute;
                height: 80%;
                aspect-ratio: 2 / 3;
                bottom: -1rem;
                left: .5rem;
                border-radius: 3px;
                transition: .1s ease;
                background: $bg-elevated-base;
            }
        }
        .basic-info{
            padding: .5rem;
            margin-top: 1rem;
            display: flex;
            flex-flow: column;
            gap: .5rem;
        }
        .preview-txt{
            display: flex;
            font-weight: normal;
            gap: .25rem;
            &.column{
                flex-flow: column;
                h3{
                    margin-bottom: 0;
                }
            }
            h3{
                font-size: 1rem;
                margin-bottom: .5rem;
            }
            p{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                margin-bottom: .5rem;
                color: $text-subdued;
                &.title{
                    font-family: $font-branch;
                    font-weight: 600;
                }
            }
            &.row{
                flex-flow: row;
                align-items: center;
                gap: .25rem;
                h2{
                    padding: 0;
                    font-weight: 600;
                    font-size: 1rem;
                    color: $text-subdued;
                    font-family: $font-branch;
                    margin-bottom: 0;
                }
                .true{
                    color: $text-positive
                }
                .false{
                    color: $text-negative;
                }
            }
            .row{
                display: flex;
                gap: .25rem;
                flex-flow: row wrap;
                .genres{
                    display: flex;
                    width: max-content;
                    padding: .3rem .5rem;
                    border-radius: 100px;
                    color: $text-subdued;
                    border: 1px solid $decorative-subdued;
                }
            }
        }
    }
}
/* .upload{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 100%;
    height: 100%;
    .form{
        max-width: 500px;
        width: 100%;
        padding: 0;
        max-height: 90vh;
        overflow-y: scroll;
        .divided{
            display: flex;
            gap: .5rem;
        }
        .form-section{
            .files{
                padding: .5rem;
                display: flex;
                flex-flow: column;
                gap: .5rem;
                >div{
                    flex: 1;
                    .input{
                        aspect-ratio: inherit;
                        width: 100%;
                        padding: .5rem;
                    }
                }
            }
        }
    }
    
} */