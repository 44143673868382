@import '../../assets/styles/variables.scss';
.modal{
    position: fixed;
    width: 100vw;
    height: 100vh;
    bottom: 0;
    left: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    max-width: inherit !important;
    align-items: center;
    &.show{
        > .backdrop {
            visibility: visible;
            opacity: .5;
        }
        > .modal-layout{
            scale: 1;
            visibility: visible;
            opacity: 1;
        }
    }
    &.loading {
        > .modal-layout {
            &::before{
                opacity: 1;
                visibility: visible;
            }
        }
    }
    .backdrop{
        background: #000000;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    > .backdrop{
        opacity: 0;
        visibility: hidden;
    }
    > .modal-layout{
        position: relative;
        max-width: 600px;
        width: 100%;
        scale: .85;
        visibility: hidden;
        opacity: 0;
        transition: .2s ease all;
        margin: .5rem;
        border: 1px solid $decorative-subdued;
        background: $bg-base;
        .modal-title{
            display: flex;
            align-items: center;
            padding-left: .5rem;
            justify-content: space-between;
            border-bottom: 1px solid $decorative-subdued;
            color: $text-base;
        }
        &.video{
            max-width: 800px;
            .modal-body{
                aspect-ratio: 16 / 9;
                iframe{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .modal-body{
            display: flex;
            flex-flow: column;
            width: 100%;
            max-height: 70vh;
            .avatar-editor{
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                aspect-ratio: 1 / 1;
                &.wide{
                    aspect-ratio: inherit;
                    .canvas{
                        height: auto !important;
                    }
                }
                &.tall {
                    max-height: 70vh;
                    .canvas{
                        width: auto !important;
                        height: 100%;
                        aspect-ratio: 2 / 3;
                    }
                }
                .canvas{
                    width: 100% !important;
                    height: 100% !important;
                }
            }
        }
    }
}