@import './variables.scss';
*{
    box-sizing: border-box;
    background: transparent;
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
    text-decoration: none;
    scroll-behavior: smooth;
    font-family: $font-primary;
}
html{
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    font-size: 16px;
    color-scheme: dark;
}

body{
    background: $bg-base;
    color: $text-base;
    overflow: hidden;
}

h1{
    font-size: 1.5rem;
    font-weight: 500;
}
h2{
    font-size: 1.25rem;
    font-weight: 400;
}
h3{
    font-size: 1.15rem;
    font-weight: 400;
}
.divider{
    width: 100%;
    height: 1px;
    background: $decorative-subdued;
}
.error-label{
    color: $text-negative;
}
button.IconButton,
a.IconButton,
li.IconButton,
button.button,
a.button,
li.button{
    color: $text-subdued;
    &.disabled,
    &:disabled{
        opacity: .5;
        color: $text-subdued;
    }
    &:hover{
        color: $text-base;
        .hover{
            visibility: visible !important;
            opacity: 1 !important;
        }
    }
}
button.button,
a.button,
li.button{
    border-radius: 0;
    padding: .5rem;
    &.disabled,
    &:disabled{
        opacity: .5;
    }
    .button-icon{
        display: flex;
        justify-content: center;
        align-items: center;
        .svg{
            width: 1.5rem;
            height: 1.5rem;
            fill: currentColor;
        }
    }
    .button-title{
        font-family: $font-branch;
        font-weight: 600;
        line-height: 1;
    }
    .loading{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        visibility: hidden;
       .progress{
        width: 1rem !important;
        height: 1rem !important;
        color: currentColor;
       } 
    }
    &.loading{
        .button-title,
        .button-icon{
            visibility: hidden;
        }
        .loading{
            visibility: visible;
        }
    }
    &.thumbnail{
        width: 300px;
        background: $bg-elevated-base;
        &.tall{
            aspect-ratio: 2 / 3;
        }
        &.wide{
            aspect-ratio: 16 / 9;
            width: 100%;
        }
        &.button{
            display: flex;
            flex-flow: column;
            gap: 1rem;
            .button-icon{
                svg{
                    width: 3rem;
                    height: 3rem;
                }
            }
            &.with-hover{
                padding: 0;
            }
            .hover{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: column;
                opacity: 0;
                visibility: hidden;
                color: $text-base;
                background: #00000086;
            }
            &:hover{
                .hover{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        picture{
            width: 100%;
            height: 100%;
            display: flex
        }
        img{
            width: 100%;
        }
    }
    &.variant-{
        &1{
            background: $branch-color;
            color: $text-base;
        }
        &2{
            &:hover{
                background: $bg-elevated-base;
            }
            &.Mui-selected{
                color: $text-base;
                background: $bg-elevated-highlight;
            }
        }
        &3{
            width: 100%;
            justify-content: flex-start;
            gap: .5rem;
            border-radius: 2px;
            &.highlight{
                background: $bg-elevated-base;
            }
            .button-icon{
                .filled{
                    display: none;
                }
            }
            .button-title{
                text-transform: capitalize;
                font-size: 1rem;
            }
            &.active{
                color: $text-base;
                background: $bg-elevated-base;
                border-radius: 5px;
                .button-icon{
                    .filled{
                        display: flex;
                    }
                    .outlined{
                        display: none;
                    }
                }
                &:hover{
                    background: $bg-elevated-highlight;
                }
            }
            &:hover{
                background: $bg-highlight;
            }
        }
        &4{
            display: flex;
            justify-content: space-between;
            background: $bg-elevated-base;
            border: 1px solid transparent;
            &.success{
                color: $text-positive;
                background: transparent;
                border: 1px solid currentColor;
            }
        }
        &icon{
            border-radius: 100px;
            &:hover{
                background: $bg-elevated-highlight;
            }
            &.active{
                background: $branch-color;
                color: $text-base;
            }
        }
        &danger{
            color: $text-negative;
            &:hover {
                background: $text-negative;
                color: $text-base;
            }
        }
        &toggle{
            display: flex;
            justify-content: space-between;
            background: $bg-highlight;
            padding: .75rem;
            .toggle{
                transition: .15s ease all;
                aspect-ratio: 16 / 4;
                height: 1rem;
                background: $bg-elevated-highlight;
                border-radius: 100px;
                position: absolute;
                top: .75rem;
                right: .5rem;
            }
            .button-title{
                font-size: 1rem;
            }
            &:hover{
                background: $bg-elevated-base;
                .toggle{
                    background: $bg-upper-base;
                }
            }
            &.active{
                background: $bg-elevated-highlight;
                color: $text-base;
                .toggle{
                    background: $branch-color;
                }
                &:hover{
                    background: $bg-upper-base;
                }
            }
        }
    }
    &.warning{
        color: $text-negative;
        &.active{
            color: $text-positive;
        }
    }
    &.outline{
        border: 1px solid $decorative-subdued;
    }
    &.size-{
        &normal{
            padding: .75rem;
        }
        &big{
            padding: 1rem;
        }
    }
}
.loading-container{
    opacity: .5;
    pointer-events: none;
}
.hide{
    opacity: 0;
    visibility: hidden;
    position: absolute;
}
.input-layout{
    display: flex;
    flex-flow: column;
    gap: .3rem;
    flex: 1;
    label{
        font-size: 1rem;
        color: $text-subdued;
    }
    .input-icon{
        fill: currentColor;
        color: currentColor;
        width: 1.5rem !important;
        height: 1.5rem !important;
        position: absolute;
        right: 0;
    }
    .input-body{
        display: flex;
        position: relative;
        align-items: center;
        button, a{
            position: absolute;
            right: 0;
        }
    }
}
.input{
    font-family: $font-primary;
    padding: .5rem;
    border-radius: 3px;
    font-size: 1rem;
    border: 1px solid transparent;
    outline: 2px solid transparent;
    transition: .15s ease all;
    flex: 1;
    width: auto;
    &::placeholder{
        color: $text-subdued;
    }
    &.text-area{
        resize: none;
    }
    &.password,
    &.with-icon{
        padding-right: 2.5rem;
    }
    &.number{
        width: 3rem;
    }
    &.variant{
        &-1{
            border-color: $decorative-subdued;
            &:hover{
                background: $bg-elevated-highlight;
            }
            &:focus{
                background: $bg-base;
                outline-color: $bg-elevated-highlight;
            }
        }
        &-search{
            border-color: $decorative-subdued;
            ~ .input-icon{
                right: .5rem;
            }
            &:hover{
                background: $bg-elevated-highlight;
            }
            &:focus{
                background: $bg-base;
                outline-color: $bg-elevated-highlight;
            }
        }
    }
}
.select{
    background: $bg-highlight;
    color: $text-base !important;
    > svg{
        color: currentColor;
    }
    fieldset{
        border-color: $decorative-subdued !important;
    }
    
    .chips{
        background: $bg-upper-base;
        color: $text-subdued;
        margin-right: .5rem;
    }
    &:hover{
        background: $bg-elevated-base;
    }
}
.MuiPaper-root{
    background-color: $bg-highlight !important;
    color: $text-base !important;
}
.form{
    transition: .2s ease all;
    display: flex;
    flex-flow: column;
    gap: 1.5rem;
    width: 100%;
    &.loading{
        opacity: .5;
        pointer-events: none;
    }
    .form-body{
        display: flex;
        flex-flow: column;
        gap: 1rem;
        padding: 1rem;
        width: 100%;
    }
}
.buttons-group{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background: $bg-elevated-base;
    padding: .5rem;
}
.circular-progress{
    color: $text-base !important;
}
.flex{
    display: flex;
    &.gap-{
        &normal{
            gap: .5rem;
        }
        &big{
            gap: 1rem;
        }
    }
    &.flow-{
        &row{
            flex-flow: row;
        }
        &column{
            flex-flow: column;
        }
    }
    &.justify-{
        &center{
            justify-content: center;
        }
        &space-between{
            justify-content: space-between;
        }
    }
    &.align-{
        &center{
            align-items: center;
        }
        &end{
            align-items: flex-end;
        }
    }
}