@import '../../assets/styles/variables.scss';
.anime-layout{
    &__header{
        display: flex;
        flex-flow: column;
        border-bottom: 1px solid $decorative-subdued;
        .data{
            padding: 2rem 0 1rem;
            &-layout{
                display: flex;
                gap: 1rem;
                margin: 0 auto;
                max-width: 900px;
                .thumbnail{
                    border-radius: 10px;
                    overflow: hidden;
                    height: 12.5rem;
                    aspect-ratio: 1 / 1;
                    background: $bg-elevated-base;
                    picture{
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        img{
                            width: 100%;
                        }
                    }
                }
                .data{
                    flex: 1;
                    display: flex;
                    align-items: flex-end;
                    h1{ 
                        font-size: 2.25rem;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        font-weight: 600;
                        font-family: $font-branch;
                    }
                    .h1{
                        height: 2.25rem;
                        width: 100%;
                        background: $bg-elevated-base;
                        border-radius: 2px;
                    }
                }
            }
        }
        .nav-tabs{
            padding: .5rem 0;
            display: flex;
            width: 100%;
            gap: .5rem;
            height: 3.5rem;
            margin: 0 auto;
            max-width: 900px;
            .button{
                background: transparent;
                flex: 1;
                border-radius: 5px;
                gap: .5rem;
                max-width: 300px;
                justify-content: flex-start;
                position: relative;
                background: $bg-elevated-highlight;
                &:hover{
                    background: $bg-upper-base;
                }
                &.active{
                    background: $branch-color;
                    color: $text-base;
                }
            }
        }
    }
    &__body{
        padding: .5rem;
        margin-top: 1rem;
        > div{
            max-width: 900px;
            margin: 0 auto;
            h2{
                font-size: 1.5rem;
                color: $text-subdued;
                margin-bottom: 1rem;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                font-weight: 600;
                font-family: $font-branch; 
            }
        }
        .visual-layout{
            margin-top: 2rem;
            .visual-body{
                display: flex;
                gap: 2.5rem;
                flex-flow: column;
                .visual-data{
                    display: flex;
                    flex-flow: column;
                    width: max-content;
                    max-width: 900px;
                    margin: 0 auto;
                    &__thumbnail{
                        display: flex;
                        flex-flow: column;
                        align-items: center;
                        flex: 1;
                        &-title{
                            margin-bottom: .5rem;
                            font-size: 1.25rem;
                            font-family: $font-branch;
                            font-weight: 600;
                            text-transform: uppercase;
                        }
                    }
                    &__actions{
                        flex: 1;
                        margin: 1rem 0 0 0;
                        .button{
                            width: 100%;
                        }
                    }
                }
            }
        }
        .anime-info{
            position: relative;
            .buttons-group{
                position: sticky;
                bottom: .5rem;
                transition: .2s;
                opacity: 1;
                visibility: visible;
                &.hiden{
                    opacity: 0;
                    visibility: hidden;
                    bottom: -5rem;
                }
            }
        }
        .seasons{
            &-header{
                display: flex;
                gap: 1rem;
            }
        }
        .upload-episode{
            border: 1px solid $decorative-subdued;
            border-radius: 2px;
            padding: 1rem;
            .buttons-group{
                max-width: 50%;
                width: 100%;
                left: 50%;
                transform: translateX(-50%);
                bottom: .5rem;
                opacity: 1;
                visibility: visible;
                position: fixed;
                transition: .2s;
                z-index: 1;
                &.hiden{
                    bottom: -2rem;
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
        .episode-edit-button{
            display: flex;
            background: $bg-highlight;
            border-radius: 5px;
            overflow: hidden;
            gap: .5rem;
            &:hover{
                background: $bg-elevated-base;
            }
            .button{
                width: auto !important;
                flex: inherit !important;
                height: 4rem !important;
                .hover{
                    .button-icon{
                        svg{
                            width: 2.25rem;
                            height: 2.25rem;
                        }
                    }
                }
            }
            .data{
                flex: 1;
                display: flex;
                align-items: center;
                gap: .75rem;
                color: $text-subdued;
                pointer-events: none;
                user-select: none;
                .text{
                    flex: 1;
                    display: flex;
                    gap: .5rem;
                    div:nth-last-child(1){
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }
                .duration{
                    
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }
        }
        .reproductors{
            display: flex;
            flex-flow: column;
            flex: 1;
            gap: .5rem;
            &-body{
                display: flex;
                flex-flow: column;
                gap: .5rem;
                &-input{
                    display: flex;
                    gap: .5rem;
                }
                .reproductors-data{
                    display: flex;
                    flex-flow: column;
                    gap: .5rem;
                    margin-top: 1rem;
                    .reproductor{
                        display: flex;
                        gap: .5rem;
                        .button.variant-2{
                            flex: 1;
                            justify-content: flex-start;
                        }
                    }
                }
            }
        }
    }
}