@import '../../../assets/styles/variables.scss';
.sidebar{
    width: 250px;
    height: 100vh;
    border-right: 1px solid $decorative-subdued;
    display: flex;
    flex-flow: column;
    &-header{
        height: 3.5rem;
        border-bottom: 1px solid $decorative-subdued;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        svg{
            height: 1.5rem;
        }
    }

    &-body{
        span.lang{
            padding: .25rem .75rem;
            border-radius: 100px;
            font-size: .8rem;
            background: $branch-color-light;
            font-family: $font-branch;
            font-weight: 600;
            left: .5rem;
            text-transform: uppercase;
            margin-bottom: 1rem;
        }
        padding: .5rem;
        display: flex;
        flex-flow: column;
    }
}