@import '../../assets/styles/variables.scss';
.search{
    display: flex;
    flex-flow: column;
    &__input-layout{
        height: 3.5rem;
        border-bottom: 1px solid $decorative-subdued;
        display: flex;
        align-items: center;
        padding: .25rem 1rem;
        position: sticky;
        top: 0;
        .input-layout{
            border: 1px solid $decorative-subdued;
            max-width: 400px;
            padding: 0 .75rem 0 .5rem;
            border-radius: 100px;
        }
    }
    .progress{
        display: flex;
        justify-content: center;
        .progress-circular{
            color: $text-base;
            width: 3rem !important;
            height: 3rem !important;
        }
    }
    &__results{
        &-layout{
            display: flex;
            justify-content: center;
            width: 100%;
        }
        &-body{
            --result-columns: 2;
            display: grid;
            width: 100%;
            margin: 0 -.75rem;
            grid-template-columns: repeat(auto-fill,minmax(calc(100% / var(--result-columns)), 1fr));
            .result-card{
                width: 100%;
                padding: .75rem;
                &:hover{
                    background: $bg-elevated-base;
                }
                &__thumbnail{
                    aspect-ratio: 16 / 9;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: $bg-elevated-base;
                    span{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    img{
                        width: 100%;
                    }
                }
                &__body{
                    h4{
                        margin: .5rem 0;
                        font-family: $font-branch;
                        color: $text-base;
                    }
                    .tags{
                        display: flex;
                        gap: .5rem;
                        span{
                            color: $text-subdued;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 60em) {
    .search{
        &__results-body{
            --result-columns: 3;
            .result-card{
                &__body{
                    h4{
                        font-size: 1.25rem;
                    }
                }
            }
        }
    }
}

@media (min-width: 75em) {
    .search{
        &__results-body{
            --result-columns: 4;
        }
    }
}